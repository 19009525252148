<template>
  <div class="home" :class="lightTheme ? 'light-theme' : 'dark-theme'">
    <Particles
      color="#fff"
      :id="'particles-1'"
      class="particles"
      :class="lightTheme ? 'light' : 'dark'"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <Particles
      color="#03A9F4"
      :id="'particles-2'"
      class="particles"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <Particles
      color="#0D47A1"
      :id="'particles-3'"
      class="particles"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <div @click="toggleTheme" class="toggle">
      <div class="mask">
        <div class="icon-wrap" :class="lightTheme ? 'active' : ''">
          <div class="icon sun"></div>
        </div>
        <div class="icon-wrap" :class="lightTheme ? '' : 'active'">
          <svg
            copy="icon"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 300"
          >
            <path
              d="M187.73 151.4c0-55.24 29.34-104.19 76.27-129.72C242.21 9.81 216.56 3 188.15 3 99.15 3 36.2 70.46 36.2 151.4S98.3 299 187.33 299c28.26 0 53.87-6.82 75.69-18.69-46.48-25.31-75.29-73.8-75.29-128.91z"
              fill="#283445"
            />
          </svg>
        </div>
      </div>
      <div class="bar" :class="lightTheme ? 'light' : 'dark'"></div>
    </div>
    <div
      v-if="showForm === false"
      class="pt-12 mt-12 text-center hero"
      :class="lightTheme ? 'light-theme' : 'dark-theme'"
      id="hero"
      style="display: block;"
    >
      <h1 class="px-8">Hello, my name is Edgar.</h1>
      <h2 class="pb-12">
        I am a
        <VueTyper
          :text="['Developer.', 'Designer.', 'Maker.']"
          :repeat="Infinity"
          :shuffle="false"
          initial-action="erasing"
          :pre-type-delay="1000"
          :type-delay="100"
          :pre-erase-delay="2000"
          :erase-delay="250"
          erase-style="select-back"
          :erase-on-complete="false"
          caret-animation="blink"
        ></VueTyper>
      </h2>
      <div class="pt-12 pb-12">
        <button @click="handleForm" class="btn button-one contact-btn">
          Get in touch.
        </button>
      </div>
    </div>
    <div
      v-if="showForm === true"
      class="pt-12 contact-form md:px-6 lg:px-6"
      :class="lightTheme ? 'light-theme' : 'dark-theme'"
      id="contact-form"
    >
      <div class="content-container">
        <div class="container-fluid section contact-flex">
          <header>
            <h2 class="text-center">Contact.</h2>
          </header>
          <div class="flex justify-center section-content">
            <form method="POST" action="https://formspree.io/xeqqpndb">
              <div class="form-group">
                <label for="fname">First Name.</label>
                <input
                  id="fname"
                  name="fname"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="form-group">
                <label for="lname">Last Name.</label>
                <input
                  id="lname"
                  name="lname"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="form-group">
                <label for="email">Email.</label>
                <input
                  id="email"
                  name="email"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="form-group">
                <label class="control-label">Comments.</label>
                <textarea class="form-control" name="comments"></textarea>
              </div>
              <div class="contact-buttons">
                <div class="pt-12">
                  <button @click="handleForm" type="button" class="mr-2 btn">
                    Go Back.
                  </button>
                  <button type="submit" value="Send" class="btn">
                    Submit.
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="social">
      <ul>
        <a
          href="https://www.linkedin.com/in/edgar-esparza-17677936/"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
        </a>
        <a href="https://twitter.com/edgares7" target="_blank">
          <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
        </a>
        <a href="https://codepen.io/edgares7/" target="_blank">
          <font-awesome-icon :icon="['fab', 'codepen']" size="2x" />
        </a>
        <a href="https://github.com/edgares7" target="_blank">
          <font-awesome-icon :icon="['fab', 'github']" size="2x" />
        </a>
      </ul>
    </div>
  </div>
</template>
<script>
import Particles from "@/components/Particles";
import { VueTyper } from "vue-typer";

export default {
  name: "home",
  data() {
    return {
      showForm: false,
      lightTheme: false
    };
  },
  components: {
    Particles,
    VueTyper
  },
  methods: {
    handleForm() {
      if (this.showForm === false) {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
      return this.showForm;
    },
    toggleTheme() {
      if (this.lightTheme == false) {
        this.lightTheme = true;
      } else {
        this.lightTheme = false;
      }
    }
  }
};
</script>
