<template>
  <div class="thank-you">
    <Particles
      color="#fff"
      :id="'particles-1'"
      class="particles"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <Particles
      color="#03A9F4"
      :id="'particles-2'"
      class="particles"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <Particles
      color="#0D47A1"
      :id="'particles-3'"
      class="particles"
      :particleOpacity="0.8"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="6"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="false"
      hoverMode="repulse"
      :clickEffect="false"
      clickMode="push"
    >
    </Particles>
    <div
      class="pt-12 mt-12 text-center text-white hero"
      id="hero"
      style="display: block;"
    >
      <h1 class="text-white">Thank you for submitting a message to me.</h1>
      <h2 class="text-center">I will be in touch shortly.</h2>
      <div class="contact-buttons">
        <div class="pt-12">
          <router-link to="/">
            <button type="button" class="mr-2 text-black btn btn-small">
              Go Back.
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="social">
      <ul>
        <a href="https://github.com/edgares7" target="_blank">
          <font-awesome-icon :icon="['fab', 'github']" size="2x" />
        </a>
        <a
          href="https://www.linkedin.com/in/edgar-esparza-17677936/"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
        </a>
        <a href="https://twitter.com/edgares7" target="_blank">
          <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
        </a>
        <a href="https://codepen.io/eesparz3/" target="_blank">
          <font-awesome-icon :icon="['fab', 'codepen']" size="2x" />
        </a>
      </ul>
    </div>
  </div>
</template>
<script>
import Particles from "@/components/Particles";

export default {
  name: "ThankYou",
  data() {
    return {
      showForm: false
    };
  },
  components: {
    Particles
  }
};
</script>
