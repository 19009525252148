import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Particles from "@/components/Particles";
import VueTyperPlugin from "vue-typer";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faTwitter,
  faGithub,
  faCodepen,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

library.add(faTwitter, faGithub, faCodepen, faLinkedin);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Particles, VueTyperPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
